import { AbsenceTypeName } from "~/modules/absence/api/absence/absenceTypes.ts";
import { OrderInvoicingStatus } from "~/modules/billing/types/billingEnums.ts";
import { BonusTypeName } from "~/modules/humanResources/api/bonusType/bonusTypes.ts";
import { CareerLevelName } from "~/modules/humanResources/api/careerLevel/careerLevelTypes.ts";
import { EmploymentTypeName } from "~/modules/humanResources/api/employmentType/employmentTypeTypes.ts";
import { TaxClassName } from "~/modules/humanResources/api/taxClass/taxClassTypes.ts";
import { ProjectTagTypeId } from "~/modules/project/api/projectTag/projectTagTypes.ts";
import { GenderNames, PermissionNames, TimeTrackingTypeNames, WorkingStatusNames } from "~/types/entityNames.ts";

export default {
	absenceType: {
		[AbsenceTypeName.Holiday]: "Feiertag",
		[AbsenceTypeName.InternalEvent]: "Interner Event",
		[AbsenceTypeName.MaternityLeave]: "Mutterschutz",
		[AbsenceTypeName.ParentalLeave]: "Elternzeit",
		[AbsenceTypeName.Sabbatical]: "Sabbatical",
		[AbsenceTypeName.Sick]: "Krank",
		[AbsenceTypeName.Special]: "Sondereffekt",
		[AbsenceTypeName.Training]: "Schulung",
		[AbsenceTypeName.Vacation]: "Urlaub",
	},
	bonusType: {
		[BonusTypeName.Fixed]: "Fixbonus",
		[BonusTypeName.Spot]: "Spot-Award",
		[BonusTypeName.Special]: "Sonderbonus",
		[BonusTypeName.Yearly]: "Jahresbonus",
	},
	careerLevel: {
		[CareerLevelName.Analyst]: "Analyst",
		[CareerLevelName.Consultant]: "Consultant",
		[CareerLevelName.SeniorConsultant]: "Senior Consultant",
		[CareerLevelName.Manager]: "Manager",
		[CareerLevelName.SeniorManager]: "Senior Manager",
		[CareerLevelName.Partner]: "Partner",
		[CareerLevelName.Assistant]: "Assistant",
		[CareerLevelName.Student]: "Student",
		[CareerLevelName.IT]: "IT",
		[CareerLevelName.ManagingDirector]: "Geschäftsführer",
	},

	employmentType: {
		[EmploymentTypeName.Employed]: "Festangestellt",
		[EmploymentTypeName.Freelancer]: "Freelancer",
		[EmploymentTypeName.Left]: "Ausgeschieden",
		[EmploymentTypeName.Student]: "Werkstudent",
		[EmploymentTypeName.Intern]: "Praktikant",
	},
	gender: {
		[GenderNames.Male]: "Männlich",
		[GenderNames.Female]: "Weiblich",
		[GenderNames.Diverse]: "Divers",
	},
	orderInvoicingStatus: {
		[OrderInvoicingStatus.Idle]: "Todo",
		[OrderInvoicingStatus.Created]: "Erstellt",
		[OrderInvoicingStatus.Sent]: "Versendet",
		[OrderInvoicingStatus.Paid]: "Bezahlt",
	},
	permission: {
		[PermissionNames.CanManageAbsences]: "Abwesenheiten anderer Benutzer verwalten",
		[PermissionNames.CanManageInvoices]: "Rechnungen verwalten",
		[PermissionNames.CanManageProjects]: "Projekte verwalten",
		[PermissionNames.CanManageProjectTags]: "Projekt-Tags verwalten",
		[PermissionNames.CanManagesStaffSalaries]: "Gehälter verwalten",
		[PermissionNames.CanManageStaff]: "Mitarbeiter verwalten",
		[PermissionNames.CanManageTimeTrackings]: "Zeiterfassungen anderer Benutzer verwalten",
		[PermissionNames.CanManageUsers]: "User verwalten",
		[PermissionNames.CanViewAbsences]: "Abwesenheiten nutzen",
		[PermissionNames.CanViewReports]: "Reports nutzen",
	},
	projectTagTypes: {
		[ProjectTagTypeId.Products]: "NN Produkte",
		[ProjectTagTypeId.SubProducts]: "NN Sub-Produkte",
		[ProjectTagTypeId.MethodsAndFrameworks]: "Methoden & Frameworks",
		[ProjectTagTypeId.Apps]: "Apps",
		[ProjectTagTypeId.Partners]: "Partner",
		[ProjectTagTypeId.ProjectKeyDeliverables]: "Projekt Key Deliverables",
	},
	taxClass: {
		[TaxClassName.I]: "Steuerklasse I",
		[TaxClassName.II]: "Steuerklasse II",
		[TaxClassName.III]: "Steuerklasse III",
		[TaxClassName.IV]: "Steuerklasse IV",
		[TaxClassName.V]: "Steuerklasse V",
	},
	timeTrackingTypes: {
		[TimeTrackingTypeNames.Project]: "Projekt",
		[TimeTrackingTypeNames.Internal]: "Intern",
		[TimeTrackingTypeNames.Sales]: "Sales",
		[TimeTrackingTypeNames.PartTime]: "Teilzeit",
	},
	workingStatus: {
		[WorkingStatusNames.Active]: "Aktiv",
		[WorkingStatusNames.Quotation]: "Angebot",
		[WorkingStatusNames.Hold]: "On hold",
		[WorkingStatusNames.Cancelled]: "Verloren",
		[WorkingStatusNames.Closed]: "Abgeschlossen",
	},
};
