import { useMemo } from "react";

import { useAvailableReportingYears } from "~/modules/reports/api/availableYears/availableYearsQueries.ts";
import type { FormInputOption } from "~/types/form.ts";
import { createInputOptionData } from "~/utils/form/formUtils.ts";
import { sortArray } from "~/utils/sortFunctions.ts";


const useAvailableReportingYearsSelectOptions = (): FormInputOption[] => {
	const { data: yearsData } = useAvailableReportingYears();
	return useMemo(() => {
		if (yearsData) {
			return sortArray(yearsData, "desc").map((year) => {
				const yearString = year.toString();
				return createInputOptionData(yearString, yearString);
			});

		}
		return [];
	}, [yearsData]);
};

export default useAvailableReportingYearsSelectOptions;

