import CreateProjectPhaseSidebar
	from "modules/project/components/ProjectDetailsView/components/CreateProjectPhaseSidebar";
import ProjectDetailsTabBar from "modules/project/components/ProjectDetailsView/components/ProjectDetailsTabBar";
import ProjectOverviewContent from "modules/project/components/ProjectDetailsView/components/ProjectOverviewContent";
import ProjectTaggingContent from "modules/project/components/ProjectDetailsView/components/ProjectTaggingContent";
import type React from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import BreadcrumbsSection from "~/components/Breadcrumbs";
import CompanyLogo from "~/components/CompanyLogo";
import ContentWrapper from "~/components/ContentWrapper";
import { SwitchPlain } from "~/components/formElements/Switch/Switch.tsx";
import Headline from "~/components/Headline";
import Status from "~/components/Status";
import { appRoutes } from "~/constants/appRoute.ts";
import type { Client } from "~/modules/client/api/client/clientTypes.ts";
import type { Company } from "~/modules/client/api/company/companyTypes.ts";
import type { AvatarImage } from "~/modules/image/api/avatarImage/avatarImageTypes.ts";
import type { Project } from "~/modules/project/api/project/projectTypes.ts";
import type { ProjectPhase } from "~/modules/project/api/projectPhase/projectPhaseTypes.ts";
import ProjectReferenceTextsContent
	from "~/modules/project/components/ProjectDetailsView/components/ProjectReferenceTextsContent";
import ProjectTimeTrackingsSection
	from "~/modules/project/components/ProjectDetailsView/components/ProjectsTimeTrackingsSection";
import { ProjectDetailsTabName } from "~/modules/project/types/projectViewTypes.ts";
import { getPhaseIdFromTabId } from "~/modules/project/utils/projectDetailsViewUtils.ts";
import type { User } from "~/modules/user/api/user/userTypes.ts";

import PhaseDetailsSection from "./components/PhaseDetailsSection";

interface ProjectDetailsViewProps {
	activeTabId: string;
	allCompanyAvatarImages: AvatarImage[];
	allUsers: User[];
	client: Client;
	company: Company;
	phases: ProjectPhase[];
	projectData: Project;
	projectHasStaffings: boolean;
}

const ProjectDetailsView: React.FC<ProjectDetailsViewProps> = ({
		activeTabId,
		allCompanyAvatarImages,
		allUsers,
		client,
		company,
		phases,
		projectData,
		projectHasStaffings,
	}) => {
		const { t } = useTranslation();
		const [selectedPhaseId, setSelectedPhaseId] = useState<null | string>(null);
		const [showCreatePhaseSidebar, setShowCreatePhaseSidebar] = useState<boolean>(false);
		const [showBudget, setShowBudget] = useState(true);

		const {
			clientId,
			comment,
			isActive,
			isInternal,
			projectNumber,
			title,
		} = projectData;

		const avatarImage = allCompanyAvatarImages.find((image) => image.id === company.avatarImageId);

		useEffect(() => {
			if (0 === phases.length) return;
			// after deleting a phase, the selectedPhaseId might not exist anymore
			const phaseExists = phases.find((p) => p.id === selectedPhaseId);
			if (selectedPhaseId && phaseExists) return;

			setSelectedPhaseId(phases[phases.length - 1].id);
		}, [phases, selectedPhaseId]);

		const allComments = [
			...phases.filter((p) => p.comment).map((p) => ({ text: p.comment, type: `Phase (${p.title})` })),
		];

		comment && allComments.unshift({ text: comment, type: "Projekt" });

		return (
			<div className="grid h-screen grid-rows-[auto_1fr] overflow-x-hidden">
				<div className="flex w-full flex-col border-b border-gray-200 bg-white">
					<BreadcrumbsSection pages={[appRoutes.projects(), title]} />
					<ContentWrapper className="w-full bg-white ">
						<div className="flex w-full justify-between">
							<div className="flex max-w-[84%] items-center gap-x-4 overflow-hidden pt-2">
								{avatarImage && <CompanyLogo image={avatarImage}
																	 width="w-12"
																	 height="h-12" />}
								<div className="flex-col items-start gap-y-0.5">
									<Headline className="max-w-full grow-0 truncate whitespace-nowrap"
											  type="h2">{title}</Headline>

									<div className="flex items-center gap-x-2">
										<div className="font-bold text-primary-400">
											{projectNumber}
										</div>
										{isInternal && <Status theme="error">INTERN</Status>}
										<Status theme={isActive ? "success" : "error"}>
											{isActive ? "Aktiv" : "Inaktiv"}
										</Status>
									</div>
								</div>
							</div>
							<div className="self-center">
								<SwitchPlain
									checked={showBudget}
									onChange={(enabled) => setShowBudget(enabled)}
									label={t("project.toggleSwitchDayCosts", "Arbeitstage / Euro")}
									labelOn="Euro"
									labelOff="Personentage"
									showAllLabels={true}
								/>
							</div>
						</div>
					</ContentWrapper>
					<ContentWrapper className="w-full">
						<ProjectDetailsTabBar
							projectId={projectData.id}
							projectPhases={phases}
							selectedTabId={activeTabId}
							onCreatePhase={() => setShowCreatePhaseSidebar(true)} />
					</ContentWrapper>
				</div>
				{activeTabId === ProjectDetailsTabName.Overview && <ProjectOverviewContent allUsers={allUsers}
																						   client={client}
																						   company={company}
																						   phases={phases}
																						   projectData={projectData}
																						   projectHasStaffings={projectHasStaffings}
																						   showBudget={showBudget} />}

				{activeTabId === ProjectDetailsTabName.ProjectTags && <ProjectTaggingContent projectData={projectData} />}

				{activeTabId === ProjectDetailsTabName.ReferenceTexts && <ProjectReferenceTextsContent projectId={projectData.id} />}
				{activeTabId.startsWith(ProjectDetailsTabName.Phase) && <PhaseDetailsSection clientId={clientId}
																							 projectId={projectData.id}
																							 phaseNumber={getPhaseIdFromTabId(activeTabId)}
																							 showBudget={showBudget}
				/>}
				{activeTabId === ProjectDetailsTabName.TimeTrackings && <ProjectTimeTrackingsSection
					projectId={projectData.id} />}

				<CreateProjectPhaseSidebar
					clientId={clientId}
					isOpen={showCreatePhaseSidebar}
					setOpen={setShowCreatePhaseSidebar}
					setSelectedPhaseId={setSelectedPhaseId}
					projectId={projectData.id}
				/>
			</div>
		);
	}
;

export default ProjectDetailsView;
