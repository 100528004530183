import { useQuery } from "@tanstack/react-query";

import { getDataFromResponse } from "~/api/axiosUtils.ts";
import { indexProjectPhasesByProjectId } from "~/modules/project/api/projectPhase/projectPhaseApiDispatchers.ts";

const ENTITY_QUERY_KEY = "projectPhase";

export const PROJECTS_PHASES_QUERY_KEY = (projectId: string) => [ENTITY_QUERY_KEY, { projectId }];

export const useProjectsProjectPhases = (projectId:string) => useQuery({
    queryKey: PROJECTS_PHASES_QUERY_KEY(projectId),
    queryFn: () => indexProjectPhasesByProjectId(projectId).then(getDataFromResponse),
	enabled: !!projectId,
});