import type React from "react";

import Headline from "~/components/Headline";
import Modal from "~/components/Modal";

type Props = {
	onCloseClick: () => void;
	isOpen: boolean;
};

const TimeTrackingTableTotalWarningModal: React.FunctionComponent<Props> = ({
	onCloseClick,
	isOpen,
}) => {
	return (
		<Modal open={isOpen}
			   widthClassName="w-1/2 min-w-[800px]"
			   setOpen={onCloseClick}>
			<div className="flex w-full flex-col gap-3 text-gray-800">
				<Headline type="h4"
						  colorClassName="text-gray-600">Warum stimmt die Tabellensumme nicht mit der monatlichen
					Rechnungssumme überein?</Headline>
				<p>In der <span className="font-bold">Rechnung</span> werden alle erfassten Zeiten (Minuten) pro User
					zuerst summiert und dann
					in Tage umgerechnet. Die errechneten Tage werden mit dem Tagessatz multipliziert und ergeben den
					Rechnungsbetrag je User.</p>
				<p>Da die <span className="font-bold">Tabelle</span> lediglich zur Übersicht aller Erfassungen mit
					zusätzlichen Filtermöglichkeiten gedacht ist, kann hier nicht das gleiche Verfahren angwandt werden.<br />Stattdessen
					erfolgt die Umrechnung von Minuten zu Tagen separat für jede Erfassung, was in Summe zu einer
					geringfügig abweichenden Anzahl an Tagen gegenüber der Rechnung
					führen kann.</p>
				<p className="font-bold">Eine der Rechnung entsprechenden Zusammenfassung der monatlichen Erfassungen
					kann direkt in der Phase
					eingesehen werden.</p>
			</div>

		</Modal>);
};

export default TimeTrackingTableTotalWarningModal;