import { CheckCircleIcon } from "@heroicons/react/20/solid";
import { ClipboardDocumentIcon, DocumentTextIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import type { FunctionComponent } from "react";
import { useEffect, useState } from "react";

import Button from "~/components/buttons/Button";
import ButtonNewItem from "~/components/buttons/ButtonNewItem";
import CardContent from "~/components/Card/components/CardContent";
import CardHeader from "~/components/Card/components/CardHeader";
import CardTitle from "~/components/Card/components/CardTitle";
import CardWrapper from "~/components/Card/components/CardWrapper";
import ContentWrapper from "~/components/ContentWrapper";
import { useCopyStyled } from "~/hooks/useCopyStyled.ts";
import {
	useProjectsReferenceTexts,
} from "~/modules/project/api/projectReferenceTexts/projectReferenceTextsQueries.ts";
import type { ProjectReferenceText } from "~/modules/project/api/projectReferenceTexts/projectReferenceTextsTypes.ts";
import CreateReferenceTextSidebar
	from "~/modules/project/components/ProjectDetailsView/components/ProjectReferenceTextsContent/components/CreateReferenceTextSidebar";
import NoReferencesExistMessage
	from "~/modules/project/components/ProjectDetailsView/components/ProjectReferenceTextsContent/components/NoReferencesExistMessage";
import UpdateReferenceTextSidebar
	from "~/modules/project/components/ProjectDetailsView/components/ProjectReferenceTextsContent/components/UpdateReferenceTextSidebar";
import LoadingPage from "~/pages/LoadingPage.tsx";
import { byObjectProperty } from "~/utils/sortFunctions.ts";

type ProjectReferenceTextsContentProps = {
	projectId: string;
};

const ProjectReferenceTextsContent: FunctionComponent<ProjectReferenceTextsContentProps> = ({ projectId }) => {
	const { copyToClipboard, copied, targetRef } = useCopyStyled({ timeout: 1000 });

	const [selectedReferenceTextId, setSelectedReferenceTextId] = useState<string | null>(null);
	const [showCreateSidebar, setShowCreateSidebar] = useState<boolean>(false);
	const [referenceTextToUpdate, setReferenceTextToUpdate] = useState<ProjectReferenceText | null>(null);
	const { data: projectReferenceTexts, isLoading } = useProjectsReferenceTexts(projectId);

	useEffect(() => {
		if (!selectedReferenceTextId && projectReferenceTexts) {
			setSelectedReferenceTextId(projectReferenceTexts[0]?.id);
		}
	}, [projectReferenceTexts, selectedReferenceTextId]);

	const referenceTextCards = projectReferenceTexts?.sort(byObjectProperty('title')).map((referenceText) => {
		return <button key={referenceText.id}
					   onClick={() => setSelectedReferenceTextId(referenceText.id)}
					   className={clsx(
						   "rounded-md px-3 py-2 text-sm shadow",
						   "font-semibold",
						   "flex items-center",
						   selectedReferenceTextId === referenceText.id ? "bg-primary-700 text-white" : "cursor-pointer bg-white hover:text-secondary-600",
					   )}>
			<DocumentTextIcon className="mr-1 size-4 shrink-0" />
			<div className="grow-0 truncate">{referenceText.title}</div>
		</button>;
	});
	const selectedReferenceText = projectReferenceTexts?.find((referenceText) => referenceText.id === selectedReferenceTextId);
	return <ContentWrapper className="grid size-full grid-cols-[20%_1fr] gap-x-8 overflow-hidden pb-10 pt-4">
		{isLoading && <div className="col-span-2 size-full"><LoadingPage /></div>}
		{!isLoading && projectReferenceTexts && projectReferenceTexts.length === 0 && <NoReferencesExistMessage onCreateReferenceClick={() => setShowCreateSidebar(true)} />}
		{!isLoading && projectReferenceTexts && projectReferenceTexts?.length > 0 && <>
			<div className="flex flex-col gap-y-2">
				{referenceTextCards}
				<ButtonNewItem theme="inline"
							   size="sm"
							   onClick={() => setShowCreateSidebar(true)}>Neue Referenz</ButtonNewItem>
			</div>
			<div className="max-h-full overflow-hidden">
				{selectedReferenceText && <CardWrapper hasTitle={true}
													   height="full">
					<CardHeader>
						<div className="flex w-full items-center justify-between">
							<CardTitle>{selectedReferenceText.title}</CardTitle>
							<div className="flex items-center gap-x-2">
								<button className={clsx("flex w-32 items-center justify-center gap-x-1  rounded-lg px-2 py-1 text-xs font-medium",
									copied ? "bg-success-500 text-white" : "bg-white text-gray-600",
								)}
										onClick={copyToClipboard}>
									{copied ?
										<CheckCircleIcon className="w-4" /> : <>
											<ClipboardDocumentIcon className="w-4" /><span>Text kopieren</span></>}

								</button>
								<Button size="auto"
										theme="plain"
										className="px-2 py-1 text-xs"
										onClick={() => setReferenceTextToUpdate(selectedReferenceText)}>Bearbeiten</Button>
							</div>
						</div>
					</CardHeader>
					<CardContent className="ProseMirror overflow-y-scroll"
								 hasTitle={true}>
						<div className="prose w-full text-sm"
							 ref={targetRef}
							 tabIndex={0}
							 dangerouslySetInnerHTML={{ __html: selectedReferenceText.text }} />
					</CardContent>
				</CardWrapper>}
			</div>
		</>}

		<CreateReferenceTextSidebar isOpen={showCreateSidebar}
									projectId={projectId}
									close={() => setShowCreateSidebar(false)} />
		<UpdateReferenceTextSidebar referenceText={referenceTextToUpdate}
									isOpen={!!referenceTextToUpdate}
									close={() => setReferenceTextToUpdate(null)} />
	</ContentWrapper>;
};

export default ProjectReferenceTextsContent;