import { CheckCircleIcon } from "@heroicons/react/20/solid";
import { ClipboardDocumentIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";

import CardContent from "~/components/Card/components/CardContent";
import CardHeader from "~/components/Card/components/CardHeader";
import CardTitle from "~/components/Card/components/CardTitle";
import CardWrapper from "~/components/Card/components/CardWrapper";
import Sidebar from "~/components/Sidebar";
import SidebarContent from "~/components/Sidebar/components/SidebarContent";
import { useCopyStyled } from "~/hooks/useCopyStyled.ts";
import {
	useAllProjectReferenceTexts,
} from "~/modules/project/api/projectReferenceTexts/projectReferenceTextsQueries.ts";

type ReferenceTextSidebarProps = {
	isOpen: boolean;
	close: () => void;
	referenceTextId: string | null;
};

const ReferenceTextSidebar: React.FunctionComponent<ReferenceTextSidebarProps> = ({
	isOpen,
	close,
	referenceTextId,
}) => {
	const { copyToClipboard, copied, targetRef } = useCopyStyled({ timeout: 1000 });
	const { data: referenceTextData } = useAllProjectReferenceTexts();

	const referenceText = referenceTextId ? referenceTextData?.find((referenceText) => referenceText.id === referenceTextId) : null;

	return (
		<Sidebar closeOnOutsideClick={false}
				 open={isOpen}
				 theme="medium"
				 setOpen={() => close()}>
			<SidebarContent>

				{referenceText && <CardWrapper hasTitle={true}
											   height="full">
					<CardHeader>
						<div className="flex w-full items-center justify-between">
							<CardTitle>{referenceText.title}</CardTitle>
							<div className="flex items-center gap-x-2">
								<button className={clsx("flex w-32 items-center justify-center gap-x-1  rounded-lg px-2 py-1 text-xs font-medium",
									copied ? "bg-success-500 text-white" : "bg-white text-gray-600",
								)}
										onClick={copyToClipboard}>
									{copied ?
										<CheckCircleIcon className="w-4" /> : <>
										<ClipboardDocumentIcon className="w-4" /><span>Text kopieren</span></>}
								</button>
							</div>
						</div>
					</CardHeader>
					<CardContent className="ProseMirror overflow-y-scroll"
								 hasTitle={true}>
						<div className="prose w-full text-sm"
							 ref={targetRef}
							 tabIndex={0}
							 dangerouslySetInnerHTML={{ __html: referenceText.text }} />
					</CardContent>
				</CardWrapper>}
			</SidebarContent>
		</Sidebar>
	);
};

export default ReferenceTextSidebar;
