import type { QueriesOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";

import { getDataFromResponse } from "~/api/axiosUtils.ts";
import { getAllOrders, getAllOrdersByProjectId, getOrder } from "~/modules/project/api/order/orderApiDispatchers.ts";
import type { UseQueryBaseParams } from "~/types/reactQueryTypes.ts";

interface UseOrderPropsType extends UseQueryBaseParams {
	orderId: string;
}

const ORDER_BASE_QUERY_KEY = "orders";

export const ORDER_QUERY_KEY = (orderId: string) => [ORDER_BASE_QUERY_KEY, orderId];
export const ALL_ORDERS_QUERY_KEY = [ORDER_BASE_QUERY_KEY, "all"];
export const PROJECTS_ORDERS_QUERY_KEY = (projectId: string) => [ORDER_BASE_QUERY_KEY, { projectId }];


export const useOrder = ({ orderId, options }: UseOrderPropsType) => useQuery({
	queryKey: ORDER_QUERY_KEY(orderId),
	queryFn: () => getOrder(orderId).then(getDataFromResponse),
	...options,
});

export const useAllOrders = (options?: QueriesOptions<any>) => useQuery({
	queryKey: ALL_ORDERS_QUERY_KEY,
	queryFn: () => getAllOrders().then(getDataFromResponse),
	...options,
});


export const useProjectsOrders = (projectId: string) => useQuery({
	queryKey: PROJECTS_ORDERS_QUERY_KEY(projectId),
	queryFn: () => getAllOrdersByProjectId(projectId).then(getDataFromResponse),
	enabled: !!projectId,
});

