import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import { setMonth } from "date-fns";
import type { FunctionComponent } from "react";
import { useCallback, useMemo, useState } from "react";

import Button from "~/components/buttons/Button";
import Card from "~/components/Card";
import SearchInput from "~/components/formElements/SearchInput";
import { SelectPlain } from "~/components/formElements/Select/Select.tsx";
import { useAllStaffMembers } from "~/modules/humanResources/api/staffMember/staffMemberQueries.ts";
import type { YearlyUtilisationReport } from "~/modules/reports/api/utilisationReport/utilisationReportTypes.ts";
import MonthlyUtilisationTable
	from "~/modules/reports/components/GlobalReportsView/components/UtilisationReportSection/components/TotalUtilisationTableSection/components/MonthlyUtilisationTable";
import DownloadYearlyUtilisationReportExcelButton
	from "~/modules/reports/components/GlobalReportsView/components/UtilisationReportSection/components/TotalUtilisationTableSection/DownloadYearlyUtilisationReportExcelButton";
import type {
	MonthlyUtilisationTableData,
	UtilisationReportDeductiblesProperty,
} from "~/modules/reports/types/utilistationReportViewTypes.ts";
import {
	calculateUtilisationPercentageFromReportData,
	calculateWorkDaysTargetFte,
} from "~/modules/reports/utils/utilisationReportUtils.ts";
import { byObjectProperty } from "~/utils/sortFunctions.ts";

type Props = {
	selectedDeductibles: UtilisationReportDeductiblesProperty[],
	selectedMonth:string;
	setSelectedMonth: (month: string) => void;
	utilisationReportData: YearlyUtilisationReport,
	year: string
};

const TotalUtilisationTableSection: FunctionComponent<Props> = ({ selectedDeductibles, selectedMonth, setSelectedMonth, utilisationReportData, year }) => {
	const [search, setSearch] = useState<string>("");
	const [monthSelectOptions, maxMonth] = useMemo(() => {
		const monthSelectOptions = utilisationReportData.monthlyBreakdown.map((month) => {
			const label = setMonth(new Date(), month.month - 1).toLocaleString("de-DE", { month: "long" });
			return { label, value: month.month.toString() };
		}).sort(byObjectProperty("value"));

		const maxMonth = parseInt(monthSelectOptions[monthSelectOptions.length - 1].value);

		return [monthSelectOptions, maxMonth];
	}, [utilisationReportData.monthlyBreakdown]);

	const handleGoToPrevMonthClick = useCallback(() => {
		const prevMonthNumber = parseInt(selectedMonth) - 1;
		if (prevMonthNumber > 0) {
			setSelectedMonth((prevMonthNumber).toString());
		}
	}, [selectedMonth, setSelectedMonth]);


	const handleGoToNextMonthClick = useCallback(() => {
		const nextMonthNumber = parseInt(selectedMonth) + 1;
		if (nextMonthNumber <= maxMonth) {
			setSelectedMonth(nextMonthNumber.toString());
		}
	}, [maxMonth, selectedMonth, setSelectedMonth]);

	const prevButtonDisabled = parseInt(selectedMonth) === 1;
	const nextButtonDisabled = parseInt(selectedMonth) === maxMonth;

	const {
		data: staffMembersData,
	} = useAllStaffMembers();
	const [tableDataByMonth,allYearTableData] = useMemo(() => {
		const tableDataByMonth: Record<number, MonthlyUtilisationTableData[]> = {};
		if (staffMembersData) {
			utilisationReportData.monthlyBreakdown.forEach((monthData) => {
				const monthTableData: MonthlyUtilisationTableData[] = [];
				monthData.staffMemberBreakdown.forEach((staffMemberMonthData) => {
					const staffMember = staffMembersData.find((staffMember) => staffMember.id === staffMemberMonthData.staffMemberId);
					const workDaysTargetFte = calculateWorkDaysTargetFte(staffMemberMonthData, selectedDeductibles);
					const utilisationPercentage = calculateUtilisationPercentageFromReportData(staffMemberMonthData, selectedDeductibles, true);
					if (staffMember) {
						monthTableData.push({
							ftePercentages: staffMemberMonthData.ftePercentages,
							longTermAbsenceDaysFte: staffMemberMonthData.longTermAbsenceDaysFte,
							month: monthData.month,
							projectMinutesTracked: staffMemberMonthData.projectMinutesTracked,
							regularWorkDays: staffMemberMonthData.regularWorkDays,
							regularWorkDaysFte: staffMemberMonthData.regularWorkDaysFte,
							sickAbsenceDaysFte: staffMemberMonthData.sickAbsenceDaysFte,
							staffMemberDisplayName: staffMember.fullName,
							trainingAndEventAbsenceDaysFte: staffMemberMonthData.trainingAndEventAbsenceDaysFte,
							utilisationPercentage,
							vacationDaysFte: staffMemberMonthData.vacationDaysFte,
							workDaysTargetFte,
						});
					}
				});
				tableDataByMonth[monthData.month] = monthTableData.sort(byObjectProperty("staffMemberDisplayName"));
			});

		}

		const allYearTableData = Object.values(tableDataByMonth).flatMap((data) => data);

		return [tableDataByMonth, allYearTableData];
	}, [utilisationReportData, selectedDeductibles, staffMembersData]);

	const tableData = useMemo(() => {
		if (tableDataByMonth) {
			return tableDataByMonth[parseInt(selectedMonth)] || [];
		}
		return [];
	}, [tableDataByMonth, selectedMonth]);

	return <div className="grid h-full grid-rows-[auto_1fr] gap-y-2 overflow-hidden">
		<div className="z-50">
			<Card>
				<div className="flex justify-between">
					<div className="flex items-center gap-x-2">
						<Button size="sm"
								theme="none"
								onClick={handleGoToPrevMonthClick}
								disabled={prevButtonDisabled}>
							<ChevronLeftIcon className="size-5 hover:fill-accent-600" />
						</Button>
						<div className="w-28">
							<SelectPlain optionsData={monthSelectOptions}
										 onChange={setSelectedMonth}
										 value={selectedMonth.toString()} />
						</div>
						<Button size="sm"
								theme="none"
								onClick={handleGoToNextMonthClick}
								disabled={nextButtonDisabled}>
							<ChevronRightIcon className="size-5 hover:fill-accent-600" />
						</Button>
					</div>
					<div className="flex items-center gap-x-2">
						<SearchInput value={search}
									 onChange={setSearch}
									 placeholder="Mitarbeiter:in" />
						<DownloadYearlyUtilisationReportExcelButton data={allYearTableData} year={parseInt(year)} />
					</div>
				</div>
			</Card>
		</div>
		<div className="max-h-full overflow-hidden">
			<MonthlyUtilisationTable tableData={tableData}
									 selectedDeductibles={selectedDeductibles}
									 search={search} /></div>
	</div>;
};

export default TotalUtilisationTableSection;