import type { AxiosApiResponsePromise } from "~/api/apiResponseTypes.ts";
import axiosApi from "~/api/axiosApi.ts";
import type { ProjectReferenceText } from "~/modules/project/api/projectReferenceTexts/projectReferenceTextsTypes.ts";

const basePath = (projectId: string) => `projects/${projectId}/reference-texts`;


export function indexAllProjectReferenceTexts(): AxiosApiResponsePromise<ProjectReferenceText[]> {
	return axiosApi.get("projects/reference-texts");
}

export function indexProjectsReferenceTexts(projectId: string): AxiosApiResponsePromise<ProjectReferenceText[]> {
	return axiosApi.get(basePath(projectId));
}

export function createProjectReferenceText({ projectId, text, title }: {
	projectId: string,
	text: string;
	title: string;
}): AxiosApiResponsePromise<void> {
	return axiosApi.post(basePath(projectId), { text, title });
}

export function updateProjectReferenceText({ projectId, referenceTextId, text, title }: {
	projectId: string,
	text: string;
	title: string;
	referenceTextId: string;
}): AxiosApiResponsePromise<void> {
	return axiosApi.put(`${basePath(projectId)}/${referenceTextId}`, { text, title });
}

export function deleteProjectReferenceText({ projectId, referenceTextId }: {
	projectId: string,
	referenceTextId: string;
}): AxiosApiResponsePromise<void> {
	return axiosApi.delete(`${basePath(projectId)}/${referenceTextId}`);
}